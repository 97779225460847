import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/preset/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token, filtro) {
        if(!filtro) filtro = '';
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/preset/get_all?token="+token+"&filtro="+filtro).then(response => {
            return response.data;
        });
    },
    async getOne(token, _id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/preset/get_one?_id="+_id+"&token="+token).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/preset/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/preset/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async uploadFileIstruzioniPasso(file, directory, token) {
        let formData = new FormData();
        formData.append('file', file);
        return await axios.post(process.env.VUE_APP_ENDPOINT_API  + "/api/passi/upload_istruzioni?token="+token+"&directory="+directory, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            return response.data;
        });
    },
}